import { BounceGame } from "./game";

export interface IFallingObject {
	sprite: string;
	doCatch: boolean;
	horizontalSpeed: string;
	verticalSpeed: string;
	bounceHeight: string;
	startRotation: string;
	rotationSpeed: string;
	changeOfSpawning: number;
	scale: string;
	collisionScale: string;
	index: number;
	points: number;
	loseLife: boolean;
}

// Object that falls down and bounces on plank
export class BouncyBall extends Phaser.Physics.Arcade.Image {
	withinReach: boolean = true;
	game: BounceGame;
	particleEmitter?: Phaser.GameObjects.Particles.ParticleEmitter;
	velocity: { x: number; y: number } = { x: 0, y: 0 };
	shouldSpeedUp: boolean = false;

	config: IFallingObject;

	justBounced: boolean = false;
	justCollided: boolean = false;

	rotationSpeed: number;

	oldVelocity: { x: number; y: number } = { x: 0, y: 0 };

	constructor(config: IFallingObject, scene: BounceGame) {
		super(scene, 0, 0, config.sprite);

		this.config = config;

		this.game = scene;
		this.scene.physics.add.existing(this);
		this.scene.add.existing(this);

		this.rotationSpeed = this.getValue(config.rotationSpeed)

		if (BounceGame.config.simplePhysics) {
			this.setImmovable(true);
		} else {
			this.setBounce(1, 1);
		}

		const scale = BounceGame.getAspectScale();

		let ballScale = this.getValue(config.scale);

		let rotation = 0;
		rotation =
			Math.random() *
			this.getValue(this.config.startRotation) *
			(Math.random() > 0.5 ? 1 : -1);
		this.setAngle(rotation);

		this.setScale(scale.x * ballScale, scale.y * ballScale);

		this.setRandomPosition(
			20,
			-this.displayHeight,
			BounceGame.width - 20,
			-this.displayHeight
		);

		this.setRandomVelocity();

		const collisionScale = this.getValue(config.collisionScale);

		this.setCircle(Math.round((this.width / 2) * collisionScale));
		const offsetX = (this.width * (1 - collisionScale)) / 2;
		const offsetY = (this.height * (1 - collisionScale)) / 2;
		this.setOffset(offsetX, offsetY);

		this.particleEmitter?.stop();

		this.particleEmitter = this.game.particles[config.sprite].createEmitter(
			{
				scale: this.scale,
				follow: this,
				alpha: {
					start: BounceGame.config.particleStartAlpha,
					end: 0,
				},
				rotate: rotation,
				frequency: 100 / BounceGame.config.particleFrequency,
			}
		);

		this.particleEmitter?.start();

		if (BounceGame.config.simplePhysics === false) {
			this.setCollideWorldBounds(true);
			this.body.onWorldBounds = true;
		}
	}

	setRandomVelocity() {
		const scale = BounceGame.getScale();

		const velocityX =
			(Math.random() > 0.5 ? 1 : -1) *
			this.getValue(this.config.horizontalSpeed) *
			this.game.speed *
			scale.x;
		const velocityY =
			this.getValue(this.config.verticalSpeed) *
			this.game.speed *
			scale.x;
		this.velocity = { x: velocityX, y: velocityY };

		this.currentVelocityX = Math.abs(velocityX);

		this.setVelocityX(velocityX * this.game.speed);

		if (!BounceGame.config.simplePhysics) {
			this.setAccelerationY(
				this.velocity.y *
					this.game.speed *
					this.game.speed *
					(this.velocity.y / (BounceGame.getScale().y * 850))
			);
		} else {
			this.setVelocityY(this.velocity.y * this.game.speed);
		}
	}

	currentVelocityX: number = 0;

	bounce() {
		if (this.withinReach === false || !this.game.catcher) {
			return;
		}

		this.rotationSpeed = this.getValue(this.config.rotationSpeed)

		this.justCollided = false;
		this.justBounced = true;
		this.body.velocity.y = -this.velocity.y * this.game.speed;

		if (BounceGame.config.simplePhysics) {
			this.body.velocity.x = this.body.velocity.x * this.game.speed;
			return;
		}

		const bounceHeight = this.getValue(this.config.bounceHeight);

		let direction = this.body.velocity.x > 0 ? 1 : -1;

		// Heeft hoekje van catcher geraakt
		if (Math.abs(this.body.velocity.x) > this.currentVelocityX) {
			direction = this.x > this.game.catcher.x ? 1 : -1;
			this.body.velocity.x =
				Math.min(
					Math.abs(this.body.velocity.x),
					2000 * BounceGame.getScale().x
				) *
				this.game.speed *
				direction;
	
		} else if (Math.abs(this.body.velocity.x) > this.velocity.x) {
			this.body.velocity.x =
				Math.abs(this.velocity.x + this.body.velocity.x)/2 * this.game.speed * direction;
		} 
		
		else {
			this.body.velocity.x =
				this.velocity.x * this.game.speed * direction;
		}

		this.body.velocity.x = Math.max(Math.abs(this.velocity.x * this.game.speed), Math.abs(this.body.velocity.x)) * direction

		this.currentVelocityX = Math.abs(this.body.velocity.x) * this.game.speed;

		// Used for 
		this.setAccelerationY(
			this.velocity.y *
				this.game.speed *
				this.game.speed *
				(this.velocity.y /
					(BounceGame.getScale().y * 850 * bounceHeight * 1))
		);

		setTimeout(() => {
			this.justBounced = false;
		}, 50);
	}

	// called by Scene update
	update(delta: number): void {
		if (this.rotationSpeed != 0) {
			let direction = 0;
			if (this.body.velocity.x === 0) {
				direction = this.angle > 180 ? 1 : -1;
			} else {
				direction = this.body.velocity.x > 0 ? 1 : -1;
			}

			const angle =
				this.angle +
				this.rotationSpeed * direction * this.game.speed;
			this.setAngle(angle);
		}

		if (BounceGame.config.simplePhysics === false) {
			return;
		}
		if (this.x < this.body.width / 2) {
			this.body.velocity.x = Math.abs(this.body.velocity.x);
			this.game.playSound("wallBounce");
		}
		if (this.x > BounceGame.width - this.body.width / 2) {
			this.body.velocity.x = -Math.abs(this.body.velocity.x);
			this.game.playSound("wallBounce");
		}
		if (this.body.velocity.y > 0 && this.justBounced) {
			this.justBounced = false;
		}
	}

	destroy(fromScene?: boolean): void {
		this.particleEmitter?.stop();
		super.destroy();
	}

	pause() {
		this.particleEmitter?.pause();
	}

	resume() {
		this.particleEmitter?.resume();
	}

	// Used for getting random value (like velocityX) if a value is defined like 10-50
	getValue(v: string) {
		const split = v.split("-");
		if (split.length === 1) {
			return +split[0];
		}

		const min = +split[0];
		const max = +split[1];

		return Math.random() * (max - min) + min;
	}
}
