import { BounceGame } from "./game";

// Player
export class Catcher extends Phaser.Physics.Arcade.Image {
  startY: number;
  game: BounceGame;
  constructor(scene: BounceGame) {
    super(
      scene,
      BounceGame.width / 2,
      BounceGame.height - (BounceGame.config.plankY / 100) * BounceGame.height,
      "catcher" // texture ID
    );

    this.scene.physics.add.existing(this);
    this.scene.add.existing(this);

    this.game = scene;

    // Dont move on collide
    this.setImmovable(true);

    this.setOrigin(0.5, 0.5);

    // Collision body size
    this.setBodySize(
      Math.round(this.width * BounceGame.config.plankCollisionScaleX),
      Math.round(this.height * BounceGame.config.plankCollisionScaleY),
      false
    );
    // Collision body offset
    this.setOffset(
      (this.width * (1 - BounceGame.config.plankCollisionScaleX)) / 2,
      0
    );
    this.setDebugBodyColor(0x1c64eb);
   

    // this.setOffset(this.width/4, this.height/4)

    const scale = BounceGame.getAspectScale();
    this.setScale(scale.x * BounceGame.config.plankScale);
    this.startY =
      this.y +
      this.displayHeight * (BounceGame.config.plankY / 100) -
      this.displayHeight / 2;
    this.setY(BounceGame.height + this.displayHeight / 2);
    this.ogScale = this.scale;
  }

  ogScale: any;

  reset() {
    this.setAlpha(1);

    this.setY(BounceGame.height + this.displayHeight / 2);
    this.setAngle(0);
  }

  appear() {
    this.scene.tweens.add({
      targets: [this],

      y: {
        to: this.startY,
        from: BounceGame.height + this.displayHeight / 2,
      },
      ease: "in",
      duration: BounceGame.config.plankBounceDownTime,
    });
  }

  lastPosition: number = BounceGame.width / 2;
  resetRotation: any;

  updatePosition(mouseX: number) {
    clearTimeout(this.resetRotation);

    // const scale = CatchGame.getScale();
    const xMax = BounceGame.width - this.body.width / 2;
    const xMin = this.body.width / 2;
    this.x = Phaser.Math.Clamp(mouseX, xMin, xMax);

    const maxRotation = BounceGame.config.plankMaxRotation;

    const dX = ((this.x - this.lastPosition) * maxRotation) / 10;

    if (dX < 0) {
      BounceGame.config.plankFlip ? this.setFlipX(true) : null;
      this.setAngle(360 - Phaser.Math.Clamp(Math.abs(dX), 0, maxRotation));
    } else if (dX > 0) {
      BounceGame.config.plankFlip ? this.setFlipX(false) : null;
      this.setAngle(Phaser.Math.Clamp(dX, 0, maxRotation));
    }

    this.resetRotation = setTimeout(() => {
      if (this.game.isGameOver) {
        return;
      }
      this.setAngle(0);
    }, 50);

    this.lastPosition = this.x;
  }

  flicker() {
    this.game.tweens.killTweensOf(this);
    this.scene.add.tween({
      name: "flicker",
      targets: this,
      yoyo: false,
      alpha: { from: 0, to: 1 },
      duration: BounceGame.config.freezeTime / BounceGame.config.flickerAmount,
      loop: BounceGame.config.flickerAmount,
    });
  }
}
